import React, { PureComponent } from 'react'
import { UIButton, UIIcon } from 'bora-material-ui'
import { translate } from '../../../Common/Translator'
import messages from '../../../../consts/messages'
import { CompanyMemberCardHeader } from './CompanyMemberCardHeader'
import cn from 'classnames'
import s from './CompanyMemberCard.module.scss'

class CompanyMemberCard extends PureComponent {
  state = {
    isInvitationSent: false,
  }

  render() {
    const { companyMember, onCardAction: handleCardAction, onRemove } = this.props
    const wrapperClasses = cn(s.wrapper, {
      [s.disabled]: !companyMember.enabled,
      [s.current]: companyMember.isCurrentUser,
    })

    return (
      <div className={wrapperClasses} data-testid="company-member-card">
        <CompanyMemberCardHeader onRemove={onRemove} companyMember={companyMember} onCardAction={handleCardAction} />

        <div className={s.userInfo}>
          <div className={s.name}>{this.getFullName()}</div>

          <div className={s.contacts}>
            <table className={s.infoTable}>
              <tbody>
                <tr>
                  <td>{`${translate(messages.id)}:`}</td>
                  <td>{companyMember.id}</td>
                </tr>
                <tr>
                  <td>{`${translate(messages.phone)}:`}</td>
                  <td>{this.getPhoneNumber()}</td>
                </tr>
                <tr>
                  <td>{`${translate(messages.email)}:`}</td>
                  <td>{companyMember.email}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {companyMember.invited && (
          <div className={s.footer}>
            <div className={s.invitationTitle}>{translate(messages.usersInvitationSent)}</div>

            <div className={s.invitationLink} onClick={this.handleInvitationClick}>
              {this.getInvitationLinkText()}
            </div>
          </div>
        )}

        {!companyMember.invited && (
          <div className={s.tickets}>
            <UIButton
              data-testid="view-ticket-button"
              click={this.handleViewTicketsClick}
              height="50px"
              type="circle"
              width="50px"
            >
              <UIIcon className={s.ticketsIcon} type="ticketsCouple" />
            </UIButton>

            <div className={s.ticketsTitle}>{translate(messages.usersViewTickets)}</div>
          </div>
        )}
      </div>
    )
  }

  handleViewTicketsClick = () => {
    const { onViewTickets, companyMember } = this.props
    onViewTickets(companyMember)
  }

  handleInvitationClick = () => {
    const { onResendInvite, companyMember } = this.props
    const { isInvitationSent } = this.state

    if (isInvitationSent) return

    onResendInvite(companyMember)

    this.setState({
      isInvitationSent: true,
    })
  }

  getFullName() {
    const { companyMember } = this.props

    const fullName = [companyMember.firstName, companyMember.lastName].filter((x) => x).join(' ')

    if (!companyMember.isCurrentUser) {
      return fullName
    }

    return `${fullName} (${translate(messages.you)})`
  }

  getInvitationLinkText() {
    const { isInvitationSent } = this.state

    if (isInvitationSent) {
      return translate(messages.usersInvitationResendSuccess)
    }

    return translate(messages.usersInvitationResend)
  }

  getPhoneNumber() {
    const { companyMember } = this.props

    if (!companyMember.phone) {
      return ''
    }

    return `(+${companyMember.phone.intlCode}) ${companyMember.phone.phoneNumber}`
  }
}

export { CompanyMemberCard }
