import React, { PureComponent } from 'react'
import messages from '../../../../consts/messages'
import style from './CompanyMemberCardHeader.module.scss'
import cn from 'classnames'
import { UIIcon } from 'bora-material-ui'
import { translate } from '../../../Common/Translator'

class CompanyMemberCardHeader extends PureComponent {
  render() {
    const { companyMember, onCardAction: handleCardAction, onRemove } = this.props
    const wrapperClasses = cn(style.wrapper, {
      [style.main]: companyMember.manager,
      [style.disabled]: !companyMember.enabled,
    })

    return (
      <div className={wrapperClasses}>
        <div className={style.userType}>
          <UIIcon className={style.crownIcon} display-if={companyMember.manager} type="crown" />
          {this.getUserType()}
        </div>

        <div className={style.buttonHolder}>
          {!companyMember.invited && (
            <div
              className={style.button}
              data-testid={
                companyMember.enabled && !companyMember.isCurrentUser
                  ? 'user-card-deactivate-button'
                  : 'user-card-edit-button'
              }
              onClick={handleCardAction}
            >
              <UIIcon className={style.actionIcon} type="iconPencil" />
              <div className={style.buttonText}>{this.getButtonText()}</div>
            </div>
          )}

          {!companyMember.enabled && (
            <div className={style.removeButton} data-testid="user-card-remove-button" onClick={onRemove}>
              <UIIcon className={style.removeIcon} type="iconCross" />
              <div className={style.buttonText}>{translate(messages.remove)}</div>
            </div>
          )}
        </div>
      </div>
    )
  }

  getButtonText() {
    const { companyMember } = this.props

    if (companyMember.isCurrentUser) {
      return translate(messages.edit)
    }

    if (companyMember.enabled) {
      return translate(messages.usersDeactivate)
    }

    return translate(messages.usersActivate)
  }

  getUserType() {
    const { companyMember } = this.props

    if (companyMember.manager) {
      return translate(messages.mainUser)
    }

    if (companyMember.invited) {
      return translate(messages.usersStatusInvited)
    }

    if (!companyMember.enabled) {
      return translate(messages.usersStatusInactive)
    }

    return translate(messages.commonUser)
  }
}

export { CompanyMemberCardHeader }
